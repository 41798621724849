import React, { useEffect, useState } from 'react';
import { Strava } from 'strava-v3';
import './App.css';
import { StravaConfig } from './Strava.config';
import firebase from 'firebase';
import { BingoCard } from './BingoCardData';
const firebaseConfig = {
  apiKey: "AIzaSyAj7cinM_hElrS9LNsYtuBT69f-8aqqJAc",
  authDomain: "rrw-bingo.firebaseapp.com",
  projectId: "rrw-bingo",
  storageBucket: "rrw-bingo.appspot.com",
  messagingSenderId: "182689606470",
  appId: "1:182689606470:web:389d525be1fe6e870c9347",
  measurementId: "G-CT5ZLV8DPT"
};
firebase.initializeApp(firebaseConfig);
//const users:firebase.firestore.DocumentReference = firebase.firestore().doc('user');
const strava:Strava = require('strava-v3');
strava.config({
  "access_token"  : StravaConfig.access_token,
  "client_id"     : StravaConfig.client_id,
  "client_secret" : StravaConfig.client_secret,
  "redirect_uri"  : StravaConfig.redirect_url,
});
function App() {
  const segmentRef:firebase.firestore.CollectionReference = firebase.firestore().collection('segments');
  const [token, setToken] = useState<any>(undefined);
  const [card, setCard] = useState<BingoCard>();
  const [firstName, setFirstName] = useState<string>('');
  const [lastName, setLastName] = useState<string>('');
  const [userSegments, setUserSegments] = useState<Array<number>>([]);
  const [bingoSegments, setBingoSegments] = useState<{[key: number]: any}>({});
  const [segments, setSegments] = useState<Array<any>>([]);
  useEffect(()=>{
    async function getToken(){
      setToken(await check_auth());
      getBingoCard();
    }
    getToken();
  }, []);

  useEffect(()=>{
    console.log("Token was changed to:");
    console.log(token);
    if(token){
      strava.client(token.access_token);
      setFirstName(token.athlete.firstname);
      setLastName(token.athlete.lastname);
      
    }
  }, [token]);

  const getBingoCard = async () =>{
    let cardRef:firebase.firestore.DocumentSnapshot = await firebase.firestore().collection('card').doc('rrw2019').get()
    console.log("I have the card details!");
    let bc:BingoCard = {
      id:cardRef?.id,
      author:cardRef.get('author'),
      card:[
        cardRef?.get('B'),
        cardRef?.get('I'),
        cardRef?.get('N'),
        cardRef?.get('G'),
        cardRef?.get('O')
      ]
    };
    setCard(bc);
    getAllSegmentDetails(bc.card);
    getSegments();
  };
  
  //https://stackoverflow.com/questions/61354866/is-there-a-workaround-for-the-firebase-query-in-limit-to-10
  function getContentById(ids:Array<string>, path:string):Promise<Array<any>>{
    return new Promise((res) => {
      // don't run if there aren't any ids or a path for the collection
      if (!ids || !ids.length || !path) return res([]);
  
      const collectionPath = firebase.firestore().collection(path);
      let batches = [];
  
      while (ids.length) {
        // firestore limits batches to 10
        const batch = ids.splice(0, 10);
  
        // add the batch request to to a queue
        batches.push(
          new Promise(response => {
            collectionPath
              .where(
                firebase.firestore.FieldPath.documentId(),
                'in',
                [...batch]
              )
              .get()
              .then(results => response(results.docs.map(result => ({ ...result.data()}) )));
          })
        );
      }
  
      // after all of the data is fetched, return it
      Promise.all(batches).then(content => {
        res(content.flat());
      });
    });
  }

  async function getAllSegmentDetails(cardSegments:[number[], number[], number[], number[], number[]]){
    let segs:Array<number> = cardSegments.flat();
    let content = await getContentById(segs.map((item)=>`${item}`), 'segments');
    console.log(content);
    let cachedSegs = content.map(item => item.id);
    let data;
    // console.log(segs.map((item)=>`${item}`));
    // console.log(segmentData.docs.length);

    for(let i = 0; i < segs.length; i++){
      if(cachedSegs.indexOf(segs[i]) !== -1){
        console.log(`Already got ${segs[i]}`);
      }else{
        console.log(`Dont' have ${segs[i]} yet!`);
        data = await getSegmentDetail(segs[i]);
        const { athlete_segment_stats, athlete_count, ...rest } = data;
        await segmentRef.doc(`${segs[i]}`).set(rest);
        content.push(rest);
        console.log(`Added ${segs[i]}`);
      }
    }
    setBingoSegments(Object.fromEntries(content.map((item) => [item.id, item])));
    
  }

  async function getSegmentDetail(id:number){
    return await strava.segments.get({id:id});
    /*let segments = {...bingoSegments};
    console.log("Before: ");
    console.log(segments);
    segments[id] = segmentDetail;
    console.log("segments:");
    console.log(segments);
    setBingoSegments({...segments});*/
  }

  //This function will get the activities for the provided date range from Strava
  async function getActivitiesForDateRange(start:Date, end:Date){
    return await strava.athlete.listActivities({after:`${start.getTime()/1000}`, before:`${end.getTime()/1000}`, per_page:200});
  }

  //There will need to be some logic in here for figuring out which days to pull activities for.
  //Anything already cached should come from firebase, and anything not yet cached should come from
  //Strava
  async function getSegments():Promise<Array<number>>{
    return [];
  }

  async function getSegmentsForActivity(activityId:number):Promise<Array<number>>{
    console.log(`Getting Activity ID ${activityId} with segment efforts:`);
    //console.log(await strava.activities.get({id:activityId, include_all_efforts:true}));
    return [];
  }

  const cellStyle:Object = {
    display:'flex',
    justifyItems: 'center',
    alignContent: 'center',
    justifyContent: 'center',
    alignItems: 'center',
  }

  return (
    <div className="App">
      {
        token !== null ? 
          <div>
            <h3>Welcome, {`${firstName} ${lastName}`}</h3>
            <div style={{
              display:'grid',
              gridTemplateColumns: '20%',
              gridTemplateRows: '30px 20%',
              width:800,
              height:800,
              margin:'auto'
            }}>
              <div style={{gridRow:1,gridColumn:1, ...cellStyle}}>B</div>
              <div style={{gridRow:1,gridColumn:2, ...cellStyle}}>I</div>
              <div style={{gridRow:1,gridColumn:3, ...cellStyle}}>N</div>
              <div style={{gridRow:1,gridColumn:4, ...cellStyle}}>G</div>
              <div style={{gridRow:1,gridColumn:5, ...cellStyle}}>O</div>
              {
                card?.card.map((column, cidx)=>{
                return column.map((val, ridx) =>{
                  return <div style={{
                    gridColumn:cidx+1,
                    gridRow:ridx+2,
                    backgroundColor: userSegments.indexOf(val) !== -1 ? '#ccffcc' : '#ffcccc',
                    ...cellStyle
                  }} key={`sq${val}`}>
                    {bingoSegments[val]?.name || val} <br/>
                    {bingoSegments[val]?.distance + ' meters' || ''}
                    </div>;
                })
              })
              }
            </div>
          </div>
        : 
          <button onClick={login}>Log in to Strava</button>
      }
    </div>
  );
}

/**
 * This function will check whether or not a user has already authorized use of Strava data
 * Good Query String: state=&code=4314fc3e3dedfcba2a4a8f03c31087c4a17cc862&scope=read,activity:read_all
 * 
 */
async function check_auth():Promise<any>{
 
  const urlSearchParams = new URLSearchParams(window.location.search);
  const code:string|null = urlSearchParams.get('code');
  if(code && code !== ''){
    console.log(`Get token from code ${code}`);
    try{
      return await strava.oauth.getToken(code);
    }catch(ex){
      console.log("Exception happened. expired token?")
      //For now, this is how I'll deal with refreshing the page when the code in the URL has already been used.
      //I'm just more concerned about seeing if I can get access to the data I need before fixing this.
      login();
    }
  }else{
    //This is really bad and needs to be more user friendly.
    console.log(`NEEDS TO LOGIN at ${await strava.oauth.getRequestAccessURL({scope:"read,activity:read_all"})}`);
    login();
    
    
  }
}

async function login(){
  window.document.location = await strava.oauth.getRequestAccessURL({scope:"read,activity:read_all"});
}

export default App;
